import React from 'react';
import { motion } from 'framer-motion';

const PersonalWebsite = () => {
  const links = [
    { name: 'Twitter', url: 'https://x.com/karthik1708', isLogo: false },
    { name: 'LinkedIn', url: 'https://www.linkedin.com/in/karthikmurugadoss/' },
    { name: 'read.cv', url: 'https://read.cv/karthikm' }
  ];

  const animationSettings = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { 
      y: {
        duration: 0.8,
        ease: "easeOut"
      },
      opacity: {
        duration: 2,
        ease: "easeInOut"
      }
    }
  };

  const XLogo = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
      <path d="M18.901 1.153h3.68l-8.04 9.19L24 22.846h-7.406l-5.8-7.584-6.638 7.584H.474l8.6-9.83L0 1.154h7.594l5.243 6.932ZM17.61 20.644h2.039L6.486 3.24H4.298Z" />
    </svg>
  );

  return (
    <div className="min-h-screen bg-black text-white p-8 cursor-auto overflow-hidden flex flex-col">
      <motion.div 
        className="w-full flex flex-col md:flex-row md:justify-between"
        {...animationSettings}
      >
        <div className="md:w-2/3 md:pl-8">
          <h1 className="text-6xl mb-2 cursor-auto text-white">
            Karthik Murugadoss
          </h1>
          <h2 className="text-6xl text-gray-400 mb-8 cursor-auto">
            Data Scientist
          </h2>
        </div>
        <div className="md:w-1/3 mb-8 md:mb-0 md:pr-8 flex flex-col items-start md:items-end justify-start">
          {links.map((link) => (
            <a
              key={link.name}
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
              className="block text-gray-400 hover:text-white mb-2 text-3xl transition-colors duration-300 cursor-pointer"
            >
              {link.isLogo ? <XLogo /> : link.name}
            </a>
          ))}
        </div>
      </motion.div>
      <motion.div 
        className="w-full md:pl-8 mt-32"
        {...animationSettings}
      >
        <div className="text-2xl text-gray-300 max-w-3xl">
          <p className="mb-4">
           I solve complex, real-world problems using AI.
          </p>
          <p>
           At my core, I'm a programmer who's been coding since childhood, weaving this passion into wide-ranging AI projects—from unraveling cancer's genomic puzzles to safeguarding patient privacy. I enjoy uncovering hidden synergies across diverse domains.
          </p>
        </div>
      </motion.div>
    </div>
  );
};

export default PersonalWebsite;